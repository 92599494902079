import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";

import SideBar from './components/Sidebar';
import sidebar_menu from './constants/sidebar-menu';

import './App.css';
import Orders from './pages/Orders';
import Teams from './pages/Teams/Teams';
import News from './pages/News/News';
import Publicroutes from './Routes/Publicroutes';
import Login from './pages/Auth/Login';
import ProtectedRoutes from './Routes/Protectedroutes';
import AuthRoutes from './Routes/AuthRoutes';
import DashboardLayout from './pages/DashboardLayout/DashboardLayout';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import { Logout } from './Redux/actions/AuthAction';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import teams from './pages/Teams';


function App () {

  axios.interceptors.response.use(
    response => {
        return response
    }, async function (error) {
        if ( 
            error.response.status === 401
        ) {
            console.log("error",error.response);
            await dispatch(Logout());
            return Promise.reject(error)
        }
        return Promise.reject(error)
    }
)
 
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [currentPage, setCurrentPage] = useState();
  
  return(


    // <>
    // {isLogin ? (
    //   <>
    <>
        <DashboardLayout />
        <ToastContainer />
    </>
      /* </>
    ) : (
      <>
        <AuthRoutes />
    
      </>
    )} */
    // </>

    // // <Publicroutes />
    // <Router>
     
    //   <div className='dashboard-container'>
    //     <SideBar menu={sidebar_menu} />
          
    //       <div className='dashboard-body'>
    //           <Routes>
    //               {/* <Route path="*" element={<div></div>} />
    //               <Route exact path="/" element={<div></div>} />
    //               <Route exact path="/blogs" element={< Orders/>} />
    //               <Route exact path="/teams" element={< Teams/>} />
    //               <Route exact path="/news" element={<News />} /> */}
                  
             
    //         <Route path="login" element={<Login />} />
            
    //         <Route path="/" element={<ProtectedRoutes />}>
    //         <Route path="blogs" element={<Orders />} />
    //         <Route path="teams" element={<Teams />} />
    //         <Route path="news" element={<News />} />
    //       </Route>

    //           </Routes>
    //       </div>
    //   </div>
    
    // </Router>
  )
}

export default App;