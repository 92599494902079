import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { onImageChangeHandler, onInputChangeHandler } from "../../utils/helpers";
import { settingget, settingpost } from "../../network/Network";
import { baseUrl } from "../../network/ApiUrl";

function Others() {
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
 
  const [fax, setFax] = useState("");
  const [copyWrite, setCopyWrite] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [logo, setLogo] = useState(null);
  const [headerLogo, setHeaderLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState([]);

  const [form, setForm] = useState({
    headerLogo: null,
    logo: null,
    title: "",
    image1: null,
    address: "",
    email: "",
    phone: "",
    fax: "",
    copyWrite: "",
    linkedin: "",
  });

  const settingHandler = (e) => {
    e.preventDefault();
    console.log("run");
    setLoading(true);
    // if (!firstName || !lastName || !email || !phone || !message || !company || !purpose ) {
    //   toast.error("Please Enter All Fields");
    //   setLoading(false);
    //   return;
    // }
    // if (
    //   !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //     email
    //   )
    // ) {
    //   toast.error("Invalid Email");
    //   setLoading(false);
    //   return;
    // }
    // if (phone.length < 10) {
    //   toast.error("The contact number must be at least 10 characters.");
    //   setLoading(false);
    //   return;
    // }
    // if (message.length < 20) {
    //   toast.error("The message must be at least 20 characters");
    //   setLoading(false);
    //   return;
    // }
    console.log("run2");
    let data = {
      headerLogo: form?.headerLogo,
      logo: form?.logo,
      title: form?.title,
      address: form?.address,
      email: form?.email,
      phone: form?.phone,
      fax: form?.fax,
      copyWrite: form?.copyWrite,
      linkedin: form?.linkedin,
    };
    console.log(data);
    settingpost(data)
      .then((res) => {
        setLoading(false);
    
        toast.success("Successfully send");
        setTitle("");
        setAddress("");
        setEmail("");
        setPhone("");
        setFax("");
        setCopyWrite("");
        setLinkedin("");
        setLogo("");
        setHeaderLogo("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    settingget()
      .then((res) => {
        setSetting(res?.data?.data);
        setForm((preval) => ({
          ...preval,
          title: res?.data?.data?.title,
          name: res?.data?.data?.name,
          address: res?.data?.data?.address,

          email: res?.data?.data?.email,
          phone: res?.data?.data?.phone,
          fax: res?.data?.data?.fax,
          linkedin: res?.data?.data?.linkedin,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <section className="other-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="team-img">
                {/* <div className="team-head">
                  {/* <img src={baseUrl + singleService?.bannerImage} width={"25%"}/> */}
                {/* <h3>Header logo</h3>
                  <input
                    type="file"
                    name="bannerImage"
                    accept="image/*" */}
                {/* //   onChange={(e) => onImageChangeHandler( e, setData )}
                    //   multiple={false}
                  /> */}
                {/* </div> */}

                <div className="team-head">
                  {/* <img src={baseUrl + singleService?.bannerImage} width={"25%"}/> */}
                  <h3>Header logo</h3>
                     <img src={baseUrl + setting?.headerLogo} width={"25%"}/>
                  <input
                    type="file"
                    name="headerLogo"
                    accept="image/*"
                    onChange={(e) => onImageChangeHandler(e,setForm)}
                    multiple={false}
                  />
                </div>

                <div className="team-head">
                  {/* <img src={baseUrl + singleService?.bannerImage} width={"25%"}/> */}
                  <h3>Footer logo</h3>
                     <img src={baseUrl + setting?.logo} width={"25%"}/>
                  <input
                    type="file"
                    name="logo"
                    accept="image/*"
                    onChange={(e) => onImageChangeHandler(e,setForm)}
                    multiple={false}
                  />
                </div>
                <div className="team-head">
                  <h3>Add Your Address</h3>
                  <input
                    type="text"
                    name="address"
                    defaultValue={setting?.address}
                    // onChange={(e) => setAddress(e.target.value)}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  />
                </div>
                <div className="team-head">
                  <h3>Add Your Email</h3>
                  <input
                    type="text"
                    name="email"
                    defaultValue={setting?.email}
                    // onChange={(e) => setEmail(e.target.value)}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  />
                </div>
                <div className="team-head">
                  <h3>Add Your Title</h3>
                  <input
                    type="text"
                    name="title"
                    defaultValue={setting?.title}
                    // onChange={(e) => setTitle(e.target.value)}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  />
                </div>
                <div className="team-head">
                  <h3>Add Your Phone Number</h3>
                  <input
                    type="text"
                    name="phone"
                    defaultValue={setting?.phone}
                    // onChange={(e) => setPhone(e.target.value)}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  />
                </div>
                <div className="team-head">
                  <h3>Add Your Fax</h3>
                  <input
                    type="text"
                    name="fax"
                    defaultValue={setting?.fax}
                    // onChange={(e) => setFax(e.target.value)}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  />
                </div>
                <div className="team-head">
                  <h3>Add Your CopyRight</h3>
                  <input
                    type="text"
                    name="copyWrite"
                    defaultValue={setting?.copyWrite}
                    // onChange={(e) => setCopyWrite(e.target.value)}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  />
                </div>

                <div className="team-head">
                  <h3>Add Your linkedin</h3>
                  <input
                    type="text"
                    name="linkedin"
                    defaultValue={setting?.linkedin}
                    // onChange={(e) => setLinkedin(e.target.value)}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  />
                </div>
                <div className="submit-btn">
                  <button
                    onClick={(e) => settingHandler(e)}
                    className="btn-sherwood "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Others;
