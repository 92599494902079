import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TextEditor from '../../components/texteditor/Texteditor';
import { baseUrl } from '../../network/ApiUrl';
import { servicesadd, servicesgetbyslug } from '../../network/Network';
import { onDirectChangeHandler, onImageChangeHandler, onInputChangeHandler } from '../../utils/helpers';

function Sales() {
    const param = useParams();
    const [heading, setHeading] = useState("");
  
    const [bannerImage, setBannerImage] = useState(null);
    const [newFile, setNewFile] = useState();
    const [loader, setLoader] = useState(false);
    const [edit, setEdit] = useState(false);
    const [fileupload, setFileupload] = useState();
    const [content, setContent] = useState("");
    const [slug, setSlug] = useState("sales");
    const [slug2, setSlug2] = useState([]);
    const [service, setService] = useState([]);
    const [bannerImages, setBannerImages] = useState();
    const [headings, setHeadings] = useState();
    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [singleService, setSingleService] = useState(null);
    const [form, setForm] = useState({
      slug: "",
      heading: "",
      bannerImage: null,
      content: "",
    });

  // Sherwood Post Api
  const serviceData = (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("heading", form?.heading);
    data.append("bannerImage", form?.bannerImage);
    data.append("content", form?.content);
    data.append("slug", slug);
    setLoader(true);
    servicesadd(data)
      .then((res) => {
        setLoader(false);
        toast.success("Successfully send");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.errors);
        console.log(err);
      });
  };
  // ==================================

      // Sherwood Get Api
    useEffect(() => {
        let data = {
          slug: `/${slug}`,
        };
        servicesgetbyslug(data)
          .then((res) => {
            setService(res?.data?.data);
            setForm((preval) => ({
              ...preval,
              heading: res?.data?.data?.heading,
              slug: slug,
              content: res?.data?.data?.content,
            }));
          })
          .catch((err) => {
            console.log(err);
          });
      }, [slug]);
      // =========================
  return (
    <>

<section className="home-sec">
        <div className="container">
          <div className="home-head add-service-content text-center">
            <h2>ADD SALES CONTENT</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="home-all">
                <div className="home-head">
                  <h3>Heading</h3>
                  <input
                    type="text"
                    name={"heading"}
                    defaultValue={service?.heading}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  ></input>
                </div>
                <div className="home-head">
                  <h3>Service Image</h3>
                  <img src={baseUrl + service?.bannerImage} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    name={"bannerImage"}
                    onChange={(e) => onImageChangeHandler(e, setForm)}
                    multiple={false}
                  />
                </div>
                <div className="home-head">
                  <h3>Section Content</h3>
                  <div className="form-group">
                    <TextEditor
                      placeholder={"Enter"}
                      content={service?.content}
                      setContent={(value) =>
                        onDirectChangeHandler("content", value, setForm)
                      }
                    />
                  </div>
                </div>
                <div className="submit-btn">
                  <button onClick={serviceData}>
                    {loader ? (
                      <i className={"fa fa-spin fa-spinner"}></i>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  )
}

export default Sales
