// Api end points

export const STORE = "/store";
export const BLOG = "/blog";
export const TEAM_MEMBER = "/teams";
export const SINGLE_TEAM_MEMBER = "/teams/show/";
export const SHOW = "/show";
export const CONTACT = "/contact";
export const GET_ALL = "/getAll";
export const DELETE = "/delete";
export const NEWS = "/news";
export const CAREER = "/career";
export const APPLICATION = "/application";
export const AUTH = "/auth";
export const REGISTER = "/register";
export const LOGIN = "/login";
export const PROFILE = "/profile";
export const API = "/api";
export const HOME ="/home-content";
export const EDIT ="/edit-home-services-content";
export const HOMEADD ="/home-services-content";
export const SERVICES ="/services";
export const ADDCONTENT ="/add-content";
export const BUSINESSADVISORY ="/business-advisory-services";
export const AssetLiquidationAndMonetization ="/asset-liquidation-and-monetization";
export const agencyip ="/agency-ip";
export const LTA ="/add-content";
export const ADD ="/add";
export const ABOUT ="/about";
export const CONTENT ="/content";
export const AFFILATION = "/affiliation";
export const AFFILATIONS = "/affilations";
export const NEWSLETTER = "/newsletter";
export const SETTING = "/setting";
export const YOURIP = "/your-ip";
export const PRESIDENTS = "/presidents";
export const UPDATE = "/update";
export const GET = "/get";
export const DEL = "/del";
export const EDITS = "/edit";


// end of Api end points
