import React from 'react'
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../network/ApiUrl";
import {addAssetLiquidationAndMonetization, addlta, servicesgetbyslug} from "../../network/Network";
import BusinessModal from "../../components/Buisnessmodal/Businessmodal";
import {Link, useParams} from "react-router-dom";
import {onDirectChangeHandler, onImageChangeHandler} from "../../utils/helpers";
import TextEditor from "../../components/texteditor/Texteditor";
import Button from "react-bootstrap/Button";
import {toast} from "react-toastify";

function LtaEdit() {
    const [singleService, setSingleService] = useState(null);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
      heading: "",
      innerHeading: "",
      short: "",
      slug: "",
      content: "",
      bannerImage: null,
      innerImage: null,
  
      section_two_image: null,
      section_two_content: '',
  
      section_three_image: null,
      section_three_content: '',
  
      section_four_image: null,
      section_four_content: '',
  
      section_five_content_1: '',
      section_five_content_2: '',
    });

    const { id } = useParams();
  
    const onChangeHandler = (e) => {
      let {name, value} = e.target;
      setData((preVal) => {
        return {
          ...preVal,
          [name]: value,
        };
      });
    };
  
    const serviceData = (e) => {
      e.preventDefault();
      let x = new FormData();
      Object.keys(data).forEach(function(key) {
        // console.log(key, data[key]);
        if (data[key]) x.append(key, data[key]);
      });
      // x.append("heading", data.heading);
      // if (data.bannerImage) {
      //   x.append("bannerImage", data.bannerImage);
      // }
      // x.append("content", data.content);
      // x.append("innerHeading", data?.innerHeading || "");
      // x.append("slug", data.slug);
  
      setLoading(true);
      addlta(x)
          .then((res) => {
            setLoading(false);
            // props.hide();
            toast.success("Successfully send");
          })
          .catch((err) => {
            setLoading(false);
          });
    };
  
  
  
    useEffect(() => {
  
      let data = {
        slug: `/${id}`,
      };
      servicesgetbyslug(data)
        .then((res) => {
          let resp = res?.data?.data;
          setSingleService( resp );
  
          setData(() => ({
  
            heading: resp?.heading,
            innerHeading: resp?.innerHeading,
            bannerImage: null,
            slug: resp?.slug,
            content: resp?.content,
            short: resp?.short,
  
            section_two_image: null,
            section_two_content: resp?.data?.section_two_content,
  
            section_three_image: null,
            section_three_content: resp?.data?.section_three_content,
  
            section_four_image: null,
            section_four_content: resp?.data?.section_four_content,
  
            section_five_content_1: resp?.data?.section_five_content_1,
            section_five_content_2: resp?.data?.section_five_content_2,
          }));
          // console.log("Data",resp);
        })
        .catch((err) => {
          console.log(err);
        });
    },[id]);
  return (
    <>
         <section className="business-sec">
        <div className="container">
          <div className="row m-3">

            <h1> Edit </h1>

            <div className="col-md-12">

              <div className="team-img">
                <div className="team-head">
                  <img src={baseUrl + singleService?.bannerImage} width={"25%"}/>
                  <input
                      type="file"
                      name="bannerImage"
                      accept="image/*"
                      onChange={(e) => onImageChangeHandler( e, setData )}
                      multiple={false}
                  />
                </div>
              </div>

              <div className="team-designation">
                <div className="team-head">
                  <h3>Inner Image</h3>

                  <img src={baseUrl + singleService?.innerImage} width={"25%"}/>
                  <input
                      type="file"
                      name="innerImage"
                      accept="image/*"
                      onChange={(e) => onImageChangeHandler(e, setData)}
                      multiple={false}
                  />
                </div>
              </div>

              <div className="team-designation">
                <div className="team-head">
                  <h3>Heading</h3>
                  <input
                      type="text"
                      name="heading"
                      // defaultValue={data?.heading}
                      value={data?.heading || ""}
                      onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="team-designation">
                <div className="team-head">
                  <h3>Inner Heading</h3>
                  <input
                      type="text"
                      name="innerHeading"
                      value={data?.innerHeading || ""}
                      onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="team-designation">
                <div className="team-head">
                  <h3>Short Content</h3>
                  <TextEditor
                      placeholder={"Enter"}
                      content={data?.short}
                      setContent={(value) =>
                          onDirectChangeHandler("short", value, setData)
                      }
                  />
                </div>
              </div>

              <div className="team-designation">
                <div className="team-head">
                  <h3>Content</h3>
                  <TextEditor
                      placeholder={"Enter"}
                      content={data?.content}
                      setContent={(value) =>
                          onDirectChangeHandler("content", value, setData)
                      }
                  />
                </div>
              </div>
              <div className={"my-3"}>
                <Link className="btn btn-info" to={'/'}>
                  Cancel
                </Link>
                <button
                    className="btn btn-primary mx-1"
                    disabled={loading}
                    onClick={(e) => serviceData(e)}
                >
                  {loading ? (
                      <i className="fa fa-spin fa-spinner"></i>
                  ) : (
                      "Save Changes"
                  )}
                </button>
              </div>

              {/* <h3>Section 2</h3>

              <div className="team-img">
                <div className="team-head">
                  <img width={"25%"}
                      src={baseUrl + singleService?.data?.section_two_image}
                  />
                  <input
                      type="file"
                      name="section_two_image"
                      accept="image/*"
                      className="form-control"
                      onChange={(e) => onImageChangeHandler(e, setData)}
                      multiple={false}
                  />
                </div>
              </div>

              <div className="team-img">
                <div className="team-head">
                  <label htmlFor="">Content</label>
                  <TextEditor
                      placeholder={"Enter"}
                      content={data?.section_two_content}
                      setContent={(value) =>
                          onDirectChangeHandler("section_two_content", value, setData)
                      }
                  />
                </div>
              </div>

              

              <h3>Section 3</h3>

              <div className="team-img">
                <div className="team-head">
                  <img width={"25%"}
                      src={baseUrl + singleService?.data?.section_three_image}
                  />
                  <input
                      type="file"
                      name="section_three_image"
                      accept="image/*"
                      className="form-control"
                      onChange={(e) => onImageChangeHandler(e, setData)}
                      multiple={false}
                  />
                </div>
              </div>

              <div className="team-img">
                <div className="team-head">
                  <label htmlFor="">Content</label>

                  <TextEditor
                      placeholder={"Enter"}
                      content={data?.section_three_content}
                      setContent={(value) =>
                          onDirectChangeHandler("section_three_content", value, setData)
                      }
                  />
                </div>
              </div>

              <h3>Section 4</h3>

              <div className="team-img">
                <div className="team-head">
                  <img width={"25%"}
                      src={baseUrl + singleService?.data?.section_four_image}
                  />
                  <input
                      type="file"
                      name="section_four_image"
                      accept="image/*"
                      className="form-control"
                      onChange={(e) => onImageChangeHandler(e, setData)}
                      multiple={false}
                  />
                </div>
              </div>

              <div className="team-img">
                <div className="team-head">
                  <label htmlFor="">Content</label>
                  <TextEditor
                      placeholder={"Enter"}
                      content={data?.section_four_content}
                      setContent={(value) =>
                          onDirectChangeHandler("section_four_content", value, setData)
                      }
                  />
                </div>
              </div>

              <h3>Section 5</h3>

              <div className="team-img">
                <div className="team-head">
                  <label htmlFor="">Content 1</label>
                  <TextEditor
                      placeholder={"Enter"}
                      content={data?.section_five_content_1}
                      setContent={(value) =>
                          onDirectChangeHandler("section_five_content_1", value, setData)
                      }
                  />
                </div>
              </div>

              <div className="team-img">
                <div className="team-head">
                  <label htmlFor="">Content 2</label>
                  <TextEditor
                      placeholder={"Enter"}
                      content={data?.section_five_content_2}
                      setContent={(value) =>
                          onDirectChangeHandler("section_five_content_2", value, setData)
                      }
                  />
                </div>
              </div> */}

            

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LtaEdit
