import { EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import TextEditor from '../../components/texteditor/Texteditor'
import { baseUrl } from '../../network/ApiUrl';
import { aboutget, aboutpost } from '../../network/Network';
import { onDirectChangeHandler, onImageChangeHandler, onInputChangeHandler } from '../../utils/helpers';

function About() {
    const [settingData, setSettingData] = useState(null);
    const [loader, setLoader] = useState(false);
    const [newFile, setNewFile] = useState();
    const [fileupload, setFileupload] = useState();
    const [bannerImage, setBannerImage] = useState(null);
    const [bannerImage3, setBannerImage3] = useState(null);
    const [smallcontent, setSmallContent] = useState("");
    const [content, setContent] = useState("");
    const [content3, setContent3] = useState("");
    const [name1, setName1] = useState("");
    const [name2, setName2] = useState("");
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [form, setForm] = useState({
    bannerImage: null,
    bannerImage3: null,
    content: "",
    content3: "",
    smallContent: "",
    name1: "",
    name2: "",
    image1: null,
    image2: null,
  });

    // About Post Api
    const Aboutdata = (e) => {
        e.preventDefault();
        var data = new FormData();
        data.append("bannerImage", form?.bannerImage);
        data.append("content", form?.content);
        data.append("smallContent", form?.smallcontent);
        data.append("bannerImage3", form?.bannerImage3);
        data.append("content3", form?.content3);
        data.append("name1", form?.name1);
        data.append("name2", form?.name2);
        data.append("image1", form?.image1);
        data.append("image2", form?.image2);
        console.log(data);
        aboutpost(data)
          .then((res) => {
        
            // setSlug2(res)
            setLoader(false);
            toast.success("Successfully send");
          })
          .catch((err) => {
            setLoader(false);
            toast.error(err?.response?.data?.errors);
            console.log(err);
          });
      };
      // ================================

      // About Get Api 
      useEffect(() => {
        aboutget()
          .then((res) => {
    
            setSettingData(res?.data?.data);
            setForm((preval) => ({
                ...preval,
      
                content: res?.data?.data?.content,
                content3: res?.data?.data?.content3,
                smallcontent: res?.data?.data?.smallContent,
              }));
              setContent(res?.data?.data?.content);
              setContent3(res?.data?.data?.content3);
              setSmallContent(res?.data?.data?.smallContent);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
      // =========================

        // Image Handler 
      // const handleImageUpload = (e) => {
      //   setBannerImage(e.target.files[0]);
      //   setBannerImage3(e.target.files[0]);
      //   setImage1(e.target.files[0]);
      //   setImage2(e.target.files[0]);
      //   e.preventDefault();
      //   const reader = new FileReader();
      //   const file = e.target.files[0];
      //   reader.onloadend = () => {
      //     setNewFile(file);
      //     console.log("file", file);
      //     setFileupload(reader.result);
      //   };
      //   reader.readAsDataURL(file);
      //   // setUploadLoading(true);
      // };
      // ===================================

      useEffect(() => {
        aboutget()
          .then((res) => {
         
            setSettingData(res?.data?.data);

            setForm((preval) => ({
              ...preval,
              content3: res?.data?.data?.content3,
              content: res?.data?.data?.content,
              smallContent: res?.data?.data?.smallContent,
              name1: res?.data?.data?.name1,
              name2: res?.data?.data?.name2,
              bannerImage: res?.data?.data?.bannerImage,
              bannerImage3: res?.data?.data?.bannerImage3,
              image1: res?.data?.data?.image1,
              image2: res?.data?.data?.image2,
            }));
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
  return (
    <>
        {/* All stuff */}
      <section className="home-sec">
        <div className="container">
          <div className="home-head add-service-content">
            <h2>ADD ABOUT CONTENT</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="home-all">
                <div className="home-head">
                  <h3>First Section Image</h3>
                  <img src={baseUrl + settingData?.bannerImage} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    name={"bannerImage"}
                    onChange={(e) => onImageChangeHandler(e, setForm)}
                    multiple={false}
                  />
                </div>
                <div className="home-head">
                  <h3>Section Content</h3>
                  <div className="form-group">
                    {/* <TextEditor placeholder={'Enter'} content={content} setContent={setContent}/> */}
                    <TextEditor
                      placeholder={"Enter"}
                      content={settingData?.content}
                      setContent={(value) =>
                        onDirectChangeHandler("content", value, setForm)
                      }
                      />
                  </div>
                </div>
                <div className="home-head">
                  <h3>Middle Content</h3>
                  <div className="form-group">
                    {/* <TextEditor placeholder={'Enter'} content={smallcontent} setContent={setSmallContent}/> */}
                    <TextEditor
                      placeholder={"Enter"}
                      content={settingData?.smallcontent}
                      setContent={(value) =>
                        onDirectChangeHandler("smallcontent", value, setForm)
                      }
                      />
                  </div>
                </div>
                <div className="home-head">
                  <h3>Section Content</h3>
                  <div className="form-group">
                    {/* <TextEditor placeholder={'Enter'} content={content3} setContent={setContent3}/> */}
                    <TextEditor
                      placeholder={"Enter"}
                      content={settingData?.content3}
                      setContent={(value) =>
                        onDirectChangeHandler("content3", value, setForm)
                      }
                      />
                  </div>
                </div>
                <div className="home-head">
                  <h3>Second Section Image</h3>
                  <img src={baseUrl + settingData?.bannerImage3} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    name={"bannerImage3"}
                    onChange={(e) => onImageChangeHandler(e, setForm)}
                    multiple={false}
                  />
                </div>

                <div className="home-head">
                  <h3>First Image</h3>
                  <img src={baseUrl + settingData?.image1} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    name={"image1"}
                    onChange={(e) => onImageChangeHandler(e, setForm)}
                    multiple={false}
                  />
                </div>

                <div className="team-designation">
                  <div className="team-head">
                    <h3>Title</h3>
                    <input
                      type="text"
                      name="name1"
                      defaultValue={settingData?.name1}
                      onChange={(e) => setName1(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="home-head">
                  <h3>First Image</h3>
                  <img src={baseUrl + settingData?.image2} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    name={"image2"}
                    onChange={(e) => onImageChangeHandler(e, setForm)}
                    multiple={false}
                  />
                </div>

                <div className="team-designation">
                  <div className="team-head">
                    <h3>Title</h3>
                    <input
                      type="text"
                      defaultValue={settingData?.name2}
                      onChange={(e) => onInputChangeHandler(e, setForm)}
                    ></input>
                  </div>
                </div>

                
                <div className="submit-btn about-sumbit">
                  <button onClick={Aboutdata}>
                    { loader ? <i className={"fa fa-spin fa-spinner"}></i> : 'Save Changes' }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =============================== */}
      
    </>
  )
}

export default About
