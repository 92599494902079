import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

import SideBarItem from './sidebar-item';

import './styles.css';
import logo from '../../assets/images/white-logo.png';
import LogoutIcon from '../../assets/icons/logout.svg';
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../Redux/actions/AuthAction";

function SideBar ({ menu }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const [type, setType] = useState("");

    const [active, setActive] = useState(1);
    const [logout, setLogout] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        menu.forEach(element => {
            if (location.pathname === element.path) {
                setActive(element.id);
            }
        });
    }, [location.pathname])

    const __navigate = (id) => {
        setActive(id);
    }

    // const LogoutHandler = (e) => {
    //     e.preventDefault();
    //     setTimeout(async () => {
    //       let x = await dispatch(logout());
    //       window.location.href = "/login";
    //     }, 600);
    //   };
    const LogoutHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(async () => {
          setLoading(false);
          let x = await dispatch(Logout());
          window.location.href = "/";
        }, 200);
      };
    return(
        <nav className='sidebar'>
            <div className='sidebar-container'>
                <div className='sidebar-logo-container'>
                    <img
                        src={logo}
                        alt="logo" />
                </div>

                <div className='sidebar-container'>
                    <div className='sidebar-items'>
                        {menu.map((item, index) => (
                            <div key={index} onClick={() => __navigate(item.id)}>
                                <SideBarItem
                                    active={item.id === active}
                                    item={item} />
                            </div>
                        ))}
                    </div>
                      
                    <div className='sidebar-footer'>
                        <span  onClick={(e) => LogoutHandler(e)}
                         className={type === "invoice" ? "active" : null}>Logout</span>
                        <img 
                            src={LogoutIcon}
                            alt='icon-logout'
                            className='sidebar-item-icon' />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default SideBar;