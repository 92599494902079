import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ServiceModal from "../../components/ServicesModal/ServiceModal";
import { baseUrl } from "../../network/ApiUrl";
import { servicesadd, servicesgetbyslug } from "../../network/Network";
import {
  onDirectChangeHandler,
  onImageChangeHandler,
  onInputChangeHandler,
} from "../../utils/helpers";
import TextEditor from "../../components/texteditor/Texteditor";

function Services() {
  const param = useParams();
  const [heading, setHeading] = useState("");

  const [bannerImage, setBannerImage] = useState(null);
  const [newFile, setNewFile] = useState();
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [fileupload, setFileupload] = useState();
  const [content, setContent] = useState("");
  const [slug, setSlug] = useState("services");
  const [slug2, setSlug2] = useState([]);
  const [service, setService] = useState([]);
  const [bannerImages, setBannerImages] = useState();
  const [headings, setHeadings] = useState();
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleService, setSingleService] = useState(null);
  const [form, setForm] = useState({
    slug: "",
    heading: "",
    bannerImage: null,
    content: "",
  });

  // Image Handler
  const handleImageUpload = (e) => {
    setBannerImage(e.target.files[0]);
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    // setUploadLoading(true);
  };
  // ===================================

  // Service Post Api
  const serviceData = (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("heading", form?.heading);
    data.append("bannerImage", form?.bannerImage);
    data.append("content", form?.content);
    data.append("slug", slug);
    setLoader(true);
    servicesadd(data)
      .then((res) => {
        setLoader(false);
        toast.success("Successfully send");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.errors);
        console.log(err);
      });
  };
  // ==================================

  // Service Get Api
  useEffect(() => {
    let data = {
      slug: `/${slug}`,
    };
    servicesgetbyslug(data)
      .then((res) => {
        setService(res?.data?.data);
        setForm((preval) => ({
          ...preval,
          heading: res?.data?.data?.heading,
          slug: slug,
          content: res?.data?.data?.content,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);
  // =========================

  return (
    <>
       {/* All stuff */}
      <section className="home-sec">
        <div className="container">
          <div className="home-head add-service-content text-center">
            <h2>ADD SERVICE CONTENT</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="home-all">
                <div className="home-head">
                  <h3>Heading</h3>
                  <input
                    type="text"
                    name={"heading"}
                    defaultValue={service?.heading}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  ></input>
                </div>
                <div className="home-head">
                  <h3>Service Image</h3>
                  <img src={baseUrl + service?.bannerImage} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    name={"bannerImage"}
                    onChange={(e) => onImageChangeHandler(e, setForm)}
                    multiple={false}
                  />
                </div>
                <div className="home-head">
                  <h3>Section Content</h3>
                  <div className="form-group">
                    <TextEditor
                      placeholder={"Enter"}
                      content={service?.content}
                      setContent={(value) =>
                        onDirectChangeHandler("content", value, setForm)
                      }
                    />
                  </div>
                </div>
                <div className="submit-btn">
                  <button onClick={serviceData}>
                    {loader ? (
                      <i className={"fa fa-spin fa-spinner"}></i>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Ger Services  */}
      <section className="service-get-sec">
        <div className="container">
          <div className="home-head add-service-content text-center">
            <h2>ADD SHERWOOD SERVICE </h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="all-table-sec  ">
                <table className="table-team service-table">
                  <tbody>
                    {service?.homeServices?.map((data, index) => {
                      return (
                        <tr>
                          <td>
                            <h3 key={data?.id}>{index + 1} </h3>
                          </td>
                          <td>
                            <img
                              className="img-fluid"
                              src={baseUrl + data?.bannerImage}
                            ></img>
                          </td>
                          <td>
                            <h3>{data?.heading}</h3>
                          </td>
                          <td>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.content,
                              }}
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-info"
                              onClick={() => {
                                setSingleService(data);
                                setEdit(true);
                              }}
                            >
                              <i class="fa fa-pencil-square-o"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Service Modal  */}
      <ServiceModal
        singleService={singleService}
        show={edit}
        hide={() => setEdit(false)}
        setService={setSingleService}
      />
      {/* ========================================== */}

      {/* ================================================================= */}
    </>
  );
}

export default Services;
