import React from 'react'
import DashboardHeader from "../../components/DashboardHeader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";
import { Modal, Button } from "react-bootstrap";
import {
  CONTACT,
  GET_ALL,
  SHOW,
  SINGLE_TEAM_MEMBER,
  TEAM_MEMBER,
} from "../../network/Endpoint";
import { ApiUrl, baseUrl } from "../../network/ApiUrl";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import { toast } from "react-toastify";
import {
  
    newsletterdelete,
    newsletterget,
  settingget,
} from "../../network/Network";
import { Link } from "react-router-dom";
import { USER_TOKEN } from "../../Redux/Types";
import { useState } from 'react';
import { useEffect } from 'react';

function Newsletter() {
    const [con, setCon] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [company, setCompany] = useState("");
    const [position, setPosition] = useState("");
    const [purpose, setPurpose] = useState("");
    const [contacttime, setContacttime] = useState("");
    const [loading, setLoading] = useState(false);
    const [settingData, setSettingData] = useState([]);
    const [state, setState] = useState();
    const [spinLoad, setSpinLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [setting, setSetting] = useState([]);
    let limit = 10;
  
    // Contact Get Api
    useEffect(() => {
        newsletterget()
          .then((res) => {
            setSetting(res?.data?.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
    // =============================
  
    // Contact Delete Api
    const DeletenewslettertHandler = (e, id) => {
      e.preventDefault();
      // setRemoveLoading(true);
      newsletterdelete(id)
        .then((res) => {
          setSetting((prev) => {
            return [...prev.filter((val) => val?._id !== id)];
          });
          // toast.success(res?.data?.message);
          // teamMember?.splice(index, 1);
          // setteamMember(res?.data?.response?.data?.data);
          // setRemoveLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // setRemoveLoading(false);
        });
    };
    // =======================================
  return (
    <>
            <section className="Contact-sec">
        <div className="Conatct-head">
          <h1>All NEWSLETTER Queries</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="contact-name">
              <table className="contact-table contac-table">
              <thead className="th-submit">
                  <th>Srno.</th>
                  <th>Emails</th>
                  <th>Del</th>
                </thead>
                {setting.map((data, index) => {
                  console.log(data);
                  return (
                    <tr>
                      {/* <td>
                        <h3 key={data?._id}>{index + 1} </h3>
                      </td>
                      <td>
                        <h3>{data?.first_name}</h3>
                      </td>
                      <td>
                        <h3>{data?.last_name}</h3>
                      </td> */}
                        <td>
                      <h3 key={data?._id}>{index + 1} </h3>
                    </td>
                      <td>
                        <h3>{data?.email}</h3>
                      </td>
                      {/* <td>
                        <h3>{data?.phone_no}</h3>
                      </td>
                      <td>
                        <h3>{data?.purpose_of_contact}</h3>
                      </td>
                      <td>
                        <h3>{data?.contact_time}</h3>
                      </td>
                      <td>
                        <h3>{data?.message}</h3>
                      </td> */}
                      <td>
                        <i
                          class="fa fa-trash-o"
                          aria-hidden="true"
                          onClick={(e) => DeletenewslettertHandler(e, data?._id)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Newsletter
