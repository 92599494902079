import React from "react";
import { Link } from "react-router-dom";

function Dash() {
  return (
    <>
      <section className="Dash-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="dash-head text-center">
                <h1>Dashboard-Overview</h1>
              </div>
              {/* <div className="dash-head">
                <h4></h4>
              </div> */}
              <div className="row for-dash-blocks">
                {/* <div className="col-md-4">
                <div  className="dash-dv dash-up">
                  <h2>Dashboard</h2>
                </div>
              </div> */}

                {/* ****************************************** */}

                <div className="col-md-3">
                <Link to="/home">
                <div className="dash-dv dash-up">
                    <h2>Home</h2>
                  </div>
                </Link>
                </div>
                <div className="col-md-3">
                  <Link to="/about">
                  <div className="dash-dv dash-up">
                    <h2>About</h2>
                  </div>
                  </Link>
                </div> 
                <div className="col-md-3">
                 <Link to="/services">
                 <div className="dash-dv dash-bottom">
                    <h2>Services</h2>
                  </div>
                 </Link>
                </div>
                <div className="col-md-3">
                  <Link to="/businessadvisory">
                  <div className="dash-dv dash-bottom">
                    <h2>Advisory</h2>
                  </div>
                  </Link>
                </div>
                <div className="col-md-3">
                 <Link to="/affilation">
                 <div className="dash-dv dash-bottom">
                    <h2>Affilation</h2>
                  </div>
                 </Link>
                </div>
                <div className="col-md-3">
               <Link to="/blogshow">
               <div className="dash-dv dash-bottom">
                    <h2>Blogs</h2>
                  </div>
               </Link>
                </div>
                <div className="col-md-3">
                 <Link to="/teams">

                 <div className="dash-dv dash-bottom">
                    <h2>Teams</h2>
                  </div>
                 </Link>
                </div>
                <div className="col-md-3">
                <Link to="/Contactus">
                <div className="dash-dv dash-bottom">
                    <h2>Contact-Us</h2>
                  </div>
                </Link>
                </div>
                <div className="col-md-3">
                  <Link to="/Newshow">
                  <div className="dash-dv dash-bottom">
                    <h2>News</h2>
                  </div>
                  </Link>
                </div>
                <div className="col-md-3">
                 <Link to="/Career">
                 <div className="dash-dv dash-bottom">
                    <h2>Career</h2>
                  </div>
                 </Link>
                </div>
                <div className="col-md-3">
                 <Link to="/Careerapplication">
                 <div className="dash-dv dash-bottom">
                    <h2>C-Application</h2>
                  </div>
                 </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Dash;
