import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter,  Redirect, Navigate, Outlet } from "react-router-dom";
import SideBar from "../components/Sidebar";
import sidebar_menu from "../constants/sidebar-menu";
// import { useSelector } from "react-redux";

function ProtectedRoutes({ children }) {
//   const isLogin = useSelector((state) => state.AuthReducer.isLogin);
    const isLogin = useSelector((state) => state.AuthReducer.isLogin);

  // const isLogin = true 
  if(isLogin === false) return <Navigate to="/login" />
  return <>
      <div className='dashboard-container'>
        <SideBar menu={sidebar_menu} />
          <div className='dashboard-body'>
          {children}
          </div>
      </div>
  </>;
}

export default ProtectedRoutes;