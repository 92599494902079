// export const ApiUrl = "https://sherwood.custom-ourbase.com/api";
// export const baseUrl = "https://sherwood.custom-ourbase.com/";

// export const ApiUrl = "http://localhost:5000/api";
// export const baseUrl = "http://localhost:5000/";

// export const ApiUrl = "http://api.shrwood.com:3001/api/";
// export const baseUrl = "http://api.shrwood.com:3001/"

export const ApiUrl = "https://api.shrwood.com/api/";
export const baseUrl = "https://api.shrwood.com/";