import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/DashboardHeader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";
import { Modal, Button } from "react-bootstrap";
import {
  CONTACT,
  GET_ALL,
  SHOW,
  SINGLE_TEAM_MEMBER,
  TEAM_MEMBER,
} from "../../network/Endpoint";
import { ApiUrl, baseUrl } from "../../network/ApiUrl";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import { toast } from "react-toastify";
import {
  applicationdelete,
  applicationget,
  contactid,
  contactshow,
  PostTeams,
  teamdelete,
} from "../../network/Network";
import { Link } from "react-router-dom";
import { USER_TOKEN } from "../../Redux/Types";

function Careerapplicaiton() {
  const [ca, setCa] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [purpose, setPurpose] = useState("");
  const [contacttime, setContacttime] = useState("");
  const [loading, setLoading] = useState(false);
  const [settingData, setSettingData] = useState([]);
  const [state, setState] = useState();
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  let limit = 10;
  useEffect(() => {
    setSpinLoad(true);
    applicationget()
      .then((res) => {

        setCa(res?.data?.data);
        // dispatch(setblogstoress(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  const DeleteNewHandler = (e, id) => {
    e.preventDefault();
    console.log("id", id);
    // setRemoveLoading(true);
    applicationdelete(id)
      .then((res) => {
        setCa((prev) => {
          return [...prev.filter((val) => val?._id !== id)];
        });

        // toast.success(res?.data?.message);
        // teamMember?.splice(index, 1);
        // setteamMember(res?.data?.response?.data?.data);
        // setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setRemoveLoading(false);
      });
  };
  // const download= (data) =>{
  //   console.log("dddd",data)
  //   let blob = new Blob(["hello"],{type:`plain/text`})
  //   let file = URL.createdObjectURL(blob)
  //   let a = document.createElement('a');
  //   a.download={data};
  //   a.href=file;
  //   a.click()
  //   }
  return (
    <>
      <section className="Contact-sec">
        <div className="Conatct-head">
          <h1>Career Application Queries</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="contact-name">
              <table className="contact-table contac-table career-table">
                <thead className="th-submit">
                  <th>Srno.</th>
                  <th>First Name</th>
                  <th>Last Name*</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Cv</th>
                  <th>Your Message</th>
                  <th>Del</th>
                </thead>
                {ca.map((data, index) => {
                  console.log(data);
                  return (
                    <tr>
                      <td>
                        <h3 key={data?.id}>{index + 1} </h3>
                      </td>
                      <td>
                        <h3>{data?.first_name}</h3>
                      </td>
                      <td>
                        <h3>{data?.last_name}</h3>
                      </td>
                      <td>
                        <h3>{data?.email}</h3>
                      </td>
                      <td>
                        <h3>{data?.phone_no}</h3>
                      </td>
                      <td>
                        <h3> {data?.csv}</h3>
                        <h3>
                          <a
                            target="_blank"
                            href={baseUrl + data?.csv}
                            download={data?.first_name}
                          >
                            Download
                          </a>
                        </h3>
                        {/* <button onClick={(e)=>download(data?.csv)}>
                          download
                         </button> */}
                      </td>
                      {/* <td>
                        <h3>{data?.mobile_no}</h3>
                      </td>
                      <td>
                        <h3>{data?.job_category}</h3>
                      </td> */}

                      <td className="career-message">
                        <h3>{data?.message}</h3>
                      </td>

                      <td>
                        <i
                          class="fa fa-trash-o"
                          aria-hidden="true"
                          onClick={(e) => DeleteNewHandler(e, data?._id)}
                        ></i>
                      </td>

                      {/* <td>
                        <h3>{data?.contacttime}</h3>
                      </td> */}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Careerapplicaiton;
