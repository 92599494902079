import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import TextEditor from "../../components/texteditor/Texteditor";
import { baseUrl } from "../../network/ApiUrl";
import { presidentget, presidentspost } from "../../network/Network";
import { onDirectChangeHandler, onImageChangeHandler, onInputChangeHandler } from "../../utils/helpers";

function President() {
  const [title1, setTitle1] = useState("");
  const [image1, setImage1] = useState("");
  const [name1, setName1] = useState("");
  const [content1, setContent1] = useState("");
  const [title2, setTitle2] = useState("");
  const [image2, setImage2] = useState("");
  const [name2, setName2] = useState("");
  const [content2, setContent2] = useState("");
  const [slug, setSlug] = useState("");
  const [loader, setLoader] = useState("");
  const [service, setService] = useState("");

  const [form, setForm] = useState({
    slug: "",
    title1: "",
    image1: null,
    name1: "",
    content1: "",
    title2: "",
    image2: null,
    name2: "",
    content2: "",
  });

  // Sherwood Post Api
  const serviceData = (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("title1", form?.title1);
    data.append("name1", form?.name1);
    data.append("image1", form?.image1);
    data.append("content1", form?.content1);
    data.append("title2", form?.title2);
    data.append("name2", form?.name2);
    data.append("image2", form?.image2);
    data.append("content2", form?.content2);
    data.append("slug", slug);
    setLoader(true);
    presidentspost(data)
      .then((res) => {
        setLoader(false);
        toast.success("Successfully send");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.errors);
        console.log(err);
      });
  };
  // ==================================

  useEffect(() => {
    presidentget()
      .then((res) => {
        setService(res?.data?.data);
        setForm((preval) => ({
          ...preval,
          title1: res?.data?.data?.title1,
          title2: res?.data?.data?.title2,
          name1: res?.data?.data?.name1,
          name2: res?.data?.data?.name2,
          slug: slug,
          content1: res?.data?.data?.content1,
          content2: res?.data?.data?.content2,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <section className="home-sec">
        <div className="container">
          <div className="home-head add-service-content text-center">
            <h2>ADD SHERWOOD-PARTNERS CONTENT</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="home-all">
                <div className="home-head">
                  <h3>Title</h3>
                  <input
                    type="text"
                    name={"title1"}
                    defaultValue={service?.title1}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  ></input>
                </div>

                <div className="home-head">
                  <h3>Name</h3>
                  <input
                    type="text"
                    name={"name1"}
                    defaultValue={service?.name1}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  ></input>
                </div>
                <div className="home-head">
                  <h3>Service Image</h3>
                  <img src={baseUrl + service?.image1} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    name={"image1"}
                    onChange={(e) => onImageChangeHandler(e, setForm)}
                    multiple={false}
                  />
                </div>
                <div className="home-head">
                  <h3>Section Content</h3>
                  <div className="form-group">
                    <TextEditor
                      placeholder={"Enter"}
                      content={service?.content1}
                      setContent={(value) =>
                        onDirectChangeHandler("content1", value, setForm)
                      }
                    />
                  </div>
                </div>

                <div className="home-head">
                  <h3>Title</h3>
                  <input
                    type="text"
                    name={"title2"}
                    defaultValue={service?.title2}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  ></input>
                </div>
                
                <div className="home-head">
                  <h3>Name</h3>
                  <input
                    type="text"
                    name={"name2"}
                    defaultValue={service?.name2}
                    onChange={(e) => onInputChangeHandler(e, setForm)}
                  ></input>
                </div>
                <div className="home-head">
                  <h3>Service Image</h3>
                  <img src={baseUrl + service?.image2} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    name={"image2"}
                    onChange={(e) => onImageChangeHandler(e, setForm)}
                    multiple={false}
                  />
                </div>

                <div className="home-head">
                  <h3>Section Content</h3>
                  <div className="form-group">
                    <TextEditor
                      placeholder={"Enter"}
                      content={service?.content2}
                      setContent={(value) =>
                        onDirectChangeHandler("content2", value, setForm)
                      }
                    />
                  </div>
                </div>

                <div className="submit-btn">
                  <button onClick={serviceData}>
                    {loader ? (
                      <i className={"fa fa-spin fa-spinner"}></i>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
                {/* <div className="submit-btn">
                  <button onClick={serviceData}>
                    {loader ? (
                      <i className={"fa fa-spin fa-spinner"}></i>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default President;
