import {
	STORE,
	BLOG,
	SHOW,
	CONTACT,
	GET_ALL,
	DELETE,
	NEWS,
	CAREER,
	APPLICATION,
	AUTH,
	REGISTER,
	LOGIN,
	PROFILE,
	TEAM_MEMBER,
	API,
	HOME,
	EDIT,
	HOMEADD,
	SERVICES,
	ADDCONTENT,
	AFFILATION,
	CONTENT,
	ABOUT, BUSINESSADVISORY, ADD, AssetLiquidationAndMonetization, agencyip, LTA,
	SETTING,
	NEWSLETTER,
	YOURIP,
	PRESIDENTS,
	UPDATE,
	AFFILATIONS,
	GET,
	DEL,
	EDITS,
} from "../network/Endpoint";

import {
	doGet,
	doPost,
	doPatch,
	doDelete,
	doPostProfilePictureUpload,
	doPutWithMulti,
	doPatchWithMulti,
} from "./Config";

export const blogstore = (data) => {
	return doPostProfilePictureUpload(`${BLOG}${STORE}`, data);
};

export const blogshow = () => {
	return doGet(`${BLOG}${SHOW}`);
};

// export const blogshow = () => {
//   return doGet(`${"/teams"}`);
// };

export const contactstore = (data) => {
	return doPost(`${CONTACT}${STORE}`, data);
};

export const contactshow = (id) => {
	return doGet(`${CONTACT}${SHOW}/${id}`);
};

export const contactid = () => {
	return doGet(`${CONTACT}${GET_ALL}`);
};

export const contactdelete = (id) => {
	return doDelete(`${CONTACT}${DELETE}/${id}`);
};

export const newsstore = (data) => {
	return doPostProfilePictureUpload(`${NEWS}${STORE}`, data);
};

export const news = () => {
	return doGet(`${NEWS}`);
};

export const newsrecord = () => {
	return doGet(`${NEWS}${SHOW}`);
};

export const newsdelete = (id) => {
	return doGet(`${NEWS}${DELETE}/${id}`);
};

export const careerstore = (data, token) => {
	return doPost(`${CAREER}${STORE}`, data, token);
};

export const careerget = () => {
	return doGet(`${CAREER}`);
};

export const careershow = () => {
	return doGet(`${CAREER}${SHOW}`);
};

export const careerdelete = (id) => {
	return doGet(`${CAREER}${DELETE}/${id}`);
};


export const applicationget = () => {
	return doGet(`${APPLICATION}`);
};
export const applicationstore = () => {
	return doPost(`${APPLICATION}${STORE}`);
};

export const PostTeams = (data) => {
	return doPostProfilePictureUpload(`${TEAM_MEMBER}${STORE}`, data);
};

export const postSignIn = (data) => {
	return doPost(`${AUTH}${LOGIN}`, { ...data });
  };

  export const logoutApi = (data) => {
	// return doPost(`${AUTH}${}`, data);
  };
  
  export const teamdelete = (id) => {
	return doGet(`${TEAM_MEMBER}${DELETE}/${id}`);
};

export const Blogdelete = (id) => {
	return doGet(`${BLOG}${DELETE}/${id}`);
};
export const teamupdate = (data, id) => {
	return doPatchWithMulti(`${TEAM_MEMBER}/${id}`,data);
};
export const teamUpdateOrderNo = (data) => {
	return doPatchWithMulti(`${TEAM_MEMBER}/orderNo`,data);
};

export const homecontent = () => {
	return doGet(`${HOME}`);
};

export const contentpost = (data) => {
	return doPostProfilePictureUpload(`${HOME}`,data);
};

export const editpost = (data,id) => {
	return doPostProfilePictureUpload(`${EDIT}/${id}`,data);
};

export const homeaddpost = (data) => {
	return doPostProfilePictureUpload(`${HOMEADD}`,data);
};

export const servicesadd = (data) => {
	return doPostProfilePictureUpload(`${SERVICES}${ADDCONTENT}`,data);
};

export const addBusinessAdvisoryServices = (data) => {
	return doPostProfilePictureUpload(`${SERVICES}${BUSINESSADVISORY}${ADD}`,data);
};

export const addAssetLiquidationAndMonetization = (data) => {
	return doPostProfilePictureUpload(`${SERVICES}${AssetLiquidationAndMonetization}${ADD}`,data);
};
export const addagencyip = (data) => {
	return doPostProfilePictureUpload(`${SERVICES}${ADDCONTENT}`,data); 
};
export const addlta = (data) => {
	return doPostProfilePictureUpload(`${SERVICES}${LTA}`,data);
};

export const servicesgetbyslug = (data) => {
	return doGet(`${SERVICES}${"/get-content"}${data?.slug}`);
};

export const affilationget = () => {
	return doGet(`${AFFILATION}${CONTENT}`);
};

export const affilationpost = (data) => {
	return doPostProfilePictureUpload(`${AFFILATION}${CONTENT}`,data);
};

export const aboutget = () => {
	return doGet(`${ABOUT}${CONTENT}`);
};

export const aboutpost = (data) => {
	return doPostProfilePictureUpload(`${ABOUT}${CONTENT}`,data);
};

export const newsletterget = () => {
	return doGet(`${NEWSLETTER}`);
};

export const settingpost = (data) => {
	return doPostProfilePictureUpload(`${SETTING}${CONTENT}`,data);
};

export const newsletterdelete = (id) => {
	return doDelete(`${NEWSLETTER}${DELETE}/${id}`);
};

export const youripget = () => {
	return doGet(`${YOURIP}`);
};

export const youripdelete = (id) => {
	return doDelete(`${YOURIP}${DELETE}/${id}`);
};


export const presidentspost = (data) => {
	return doPostProfilePictureUpload(`${PRESIDENTS}${CONTENT}`,data);
};
export const presidentget = () => {
	return doGet(`${PRESIDENTS}${CONTENT}`);
};

export const newsupdate = (data, id) => {
	return doPostProfilePictureUpload(`${NEWS}${UPDATE}/${id}`,data);
};

export const blogsupdate = (data, id) => {
	return doPostProfilePictureUpload(`${BLOG}${UPDATE}/${id}`,data);
};

export const settingget = () => {
	return doGet(`${SETTING}${CONTENT}`);
};

export const applicationdelete = (id) => {
	return doGet(`${APPLICATION}${DEL}/${id}`);
};

export const affilationform = () => {
	return doGet(`${AFFILATIONS}${GET}`,);
}; 

export const affaltiondelete = (id) => {
	return doGet(`${AFFILATIONS}${DELETE}/${id}`);
};

export const careerupdate = (data, id,Token) => {
	return doPost(`${CAREER}${EDITS}/${id}`,data,Token);
};
