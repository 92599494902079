import React from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from '../../components/Sidebar';
import Orders from '../Orders';
import Teams from '../Teams/Teams';
import News from '../News/News';
import ProtectedRoutes from '../../Routes/Protectedroutes';
import sidebar_menu from '../../constants/sidebar-menu';
import Blogshow from '../Orders/Blogsshow';
import Teamdetail from '../Teams/Teamdetail';
import Contac from '../Contact/Contac';
import Newshow from '../News/Newshow';
import Dash from '../Dashboard/Dash';
import Career from '../Career/Career';
import Careerapplicaiton from '../Career/Careerapplicaiton';
import Home from '../Home/Home';
import Services from '../Services/Services';
import Affilation from '../Affilation/Affilation';
import Business from '../Businessadvisory/Business';
import About from '../About/About';
import { addAssetLiquidationAndMonetization } from "../../network/Network";
import Liquid from "../Liquid/Liquid";
// <<<<<<< HEAD
import EditLiquid from "../Liquid/Edit";
// =======
import Sherwoodpartners from '../SherwoodPartners/Sherwoodpartners';
import Firsttime from '../Firsttime/Firsttime';
import Agencyip from '../Agencyip/Agencyip';
import Agencyedit from '../Agencyip/Agencyedit';
import Lta from '../Lta/Lta';
import Ltaedit from '../Lta/Ltaedit';
import Others from '../Others/Others';
import LtaEdit from "../Lta/Ltaedit";
import Sf from '../Sherwoodfinance/Sf';
import Sfmodal from '../../components/Sfmodal/Sfmodal';
import Newsletter from '../Newsletter/Newsletter';
import Submitip from '../Submitip/Submitip';
import President from '../President/President';
import Sales from '../Sales/Sales';
import Purchase from '../Purchase/Purchase';
import Login from '../Auth/Login';
import Getaffilation from '../Affilation/Getaffilation';


const routes = [
  { path : '/' , component : <Dash /> },
  { path : '/blogs' , component : <Orders /> },
  { path : '/blogshow' , component : <Blogshow /> },
  { path : '/teams' , component : <Teams /> },
  { path : '/news' , component : <News /> },
  { path : '/team-detail/:Id' , component : <Teamdetail /> },
  { path : '/Contactus' , component : <Contac /> },
  { path : '/Newshow' , component : <Newshow /> },
  { path : '/Career' , component : <Career /> },
  { path : '/Careerapplication' , component : <Careerapplicaiton /> },
  { path : '/home' , component : <Home /> },
  { path : '/services' , component : <Services /> },
  { path : '/affilation' , component : <Affilation /> },
  { path : '/businessadvisory' , component : <Business /> },
  { path : '/asset-liquidation-and-monetization' , component : <Liquid /> },
  { path : '/asset-liquidation-and-monetization/:id' , component : <EditLiquid /> },
  { path : '/about' , component : <About /> },
  { path : '/sherwoodpartners' , component : <Sherwoodpartners /> },
  { path : '/firsttime' , component : <Firsttime /> },
  { path : '/agencyip' , component : <Agencyip /> },
  { path : '/agencyip/:id' , component : <Agencyedit /> },
  { path : '/lta' , component : <Lta /> },
  { path : '/lta/:id' , component : <LtaEdit /> },
  { path : '/others' , component : <Others /> },
  { path : '/sf' , component : <Sf /> },
  { path : '/sfm' , component : <Sfmodal /> },
  { path : '/newsletter' , component : <Newsletter /> },
  { path : '/submitip' , component : <Submitip /> },
  { path : '/sales' , component : <Sales /> },
  { path : '/purchase' , component : <Purchase /> },
  { path : '/president' , component : <President /> },
  { path : '/getaffialtion' , component : <Getaffilation /> },

];

const Wrapper = ({children}) => {
  return <>
    {children}
    </>
}

function DashboardLayout () {

  return(  
    <Router>
     
      {/* <div className='dashboard-container'>
        <SideBar menu={sidebar_menu} /> */}
          
          {/* <div className='dashboard-body'> */}
              <Routes>
             
            <Route path="login" element={<Login />} />
            {
              routes?.map((val,key)=>(
                <Route path={val?.path} key={key} element={
                <ProtectedRoutes>
                    <Wrapper>
                      {val?.component}
                    </Wrapper>
                  </ProtectedRoutes>
                } />
              ))
            }
            
            {/* <Route path="/" element={<ProtectedRoutes />}> */}
            {/* <Route element={<ProtectedRoutes />}> */}
              {/* <Route path="/" element={<ProtectedRoutes> <Dash/> </ProtectedRoutes>} />
              <Route path="blogs" element={<Orders />} />
              <Route path="blogshow" element={<Blogshow />} />
              <Route path="teams" element={<Teams />} />
              <Route path="news" element={<News />} />
              <Route path="/team-detail/:Id" element={<Teamdetail />}/>
              <Route path="/Contactus" element={<Contac/>} />
              <Route path="/Newshow" element={<Newshow/>} />
              <Route path="/Career" element={<Career/>} />
              <Route path="/Careerapplication" element={<Careerapplicaiton/>} />
              <Route path="/home" element={<Home/>} />
              <Route path="/services" element={<Services/>} />
              <Route path="/affilation" element={<Affilation/>} />
              <Route path="/businessadvisory" element={<Business/>} />
              <Route path="/asset-liquidation-and-monetization" element={<Liquid/>} />
              <Route path="/asset-liquidation-and-monetization/:id" element={<EditLiquid />} />
              <Route path="/about" element={<About/>} />
              <Route path="/sherwoodpartners" element={<Sherwoodpartners/>} />
              <Route path="/firsttime" element={<Firsttime/>} />
              <Route path="/agencyip" element={<Agencyip/>} />
              <Route path="/agencyip/:id" element={<Agencyedit/>} />
              <Route path="/lta" element={<Lta/>} />
              <Route path="/lta/:id" element={<LtaEdit/>} />
              <Route path="/others" element={<Others/>} />
              <Route path="/sf" element={<Sf/>} />
              <Route path="/sfm" element={<Sfmodal/>} />
              <Route path="/newsletter" element={<Newsletter/>} />
              <Route path="/submitip" element={<Submitip/>} />
              <Route path="/president" element={<President/>} />
              <Route path="/sales" element={<Sales/>} />
              <Route path="/purchase" element={<Purchase/>} /> */}
            {/* </Route> */}

          {/* </Route> */}

              </Routes>
          {/* </div>
      </div> */}
    
    </Router>
  )
}

export default DashboardLayout;