import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { baseUrl } from "../../network/ApiUrl";
import { servicesadd } from "../../network/Network";
import {
  onDirectChangeHandler,
  onImageChangeHandler,
  onInputChangeHandler,
} from "../../utils/helpers";
import TextEditor from "../texteditor/Texteditor";

const ServiceModal = (props) => {
  const [heading, setHeading] = useState();
  const [fileupload, setFileupload] = useState();
  const [bannerImage, setBannerImage] = useState(null);
  const [newFile, setNewFile] = useState();
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    heading: "",
    slug: "",
    bannerImage: null,
    content: "",
  });
  const onChangeHandler = (e) => {
    let { name, value } = e.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };
  //   const {
  //     serviceData,
  //     loading,
  //   } = props;

  // Image Handler
  const handleImageUpload = (e) => {
    setBannerImage(e.target.files[0]);
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      console.log("file", file);
      setData((preVal) => ({
        ...preVal,
        bannerImage: file,
      }));
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    // setUploadLoading(true);
  };
  // ====================================

  // Service Post Api
  const serviceData = (e) => {
    console.log(data);
    e.preventDefault();
    let x = new FormData();
    x.append("heading", data.heading);
    if (data.bannerImage) {
      x.append("bannerImage", data.bannerImage);
    }
    x.append("content", data.content);
    x.append("innerHeading", data?.innerHeading || "");
    x.append("slug", data.slug);
    if (data?.slug === "agency-ip") {
      if (data) x.append("innerImage", data?.innerImage);
      x.append("short", data.short);
    }
    if (data?.slug === "wellness-reviews") {
      x.append("sectionImage1", data?.sectionImage1);
      x.append("sectionContent1", data?.sectionContent1);
      if (data?.sectionImage2) x.append("sectionImage2", data?.sectionImage2);
      x.append("sectionHeading2", data?.sectionHeading2);
      x.append("sectionContent2", data?.sectionContent2);
      if (data?.sectionImage3) x.append("sectionImage3", data?.sectionImage3);
      x.append("sectionContent3", data?.sectionContent3);
      if (data?.sectionImage4) x.append("sectionImage4", data?.sectionImage4);
      x.append("sectionContent4", data?.sectionContent4);
    }
    setLoading(true);
    servicesadd(x)
      .then((res) => {
        // console.log(res,":::");
        // setSlug2(res)
        setLoading(false);
        props.hide();
        toast.success("Successfully send");
      })
      .catch((err) => {
        setLoading(false);
        // toast.error(err?.response?.data?.errors);
        console.log(err);
      });
  };
  // =================================


  useEffect(() => {
    setData(() =>{
     let x = {
       heading: props.singleService?.heading,
       innerHeading: props.singleService?.innerHeading,
       bannerImage: null,
       slug: props.singleService?.slug,
       content: props.singleService?.content,
       short: props?.singleService?.short,
     };
      if (props.singleService?.slug === "wellness-reviews") {
        x.sectionImage1 = null;
        x.sectionImage2 = null;
        x.sectionImage3 = null;
        x.sectionImage4 = null;
        x.sectionContent1 = props.singleService?.data?.sectionContent1;
        x.sectionHeading2 = props.singleService?.data?.sectionHeading2;
        x.sectionContent2 = props.singleService?.data?.sectionContent2;
        x.sectionContent3 = props.singleService?.data?.sectionContent3;
        x.sectionContent4 = props.singleService?.data?.sectionContent4;
      }
     return x;
    });
  }, [props.singleService]);

  console.log("Data", data,props.singleService);


  return (
    <>
      {/* All stuff */}
      <Modal
        show={props.show}
        onHide={() => {
          props.hide();
          // props.setService(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="team-img">
            <div className="team-head">
              <img src={baseUrl + props.singleService?.bannerImage} />
              <input
                type="file"
                name="bannerImage"
                accept="image/*"
                onChange={handleImageUpload}
                multiple={false}
              />
            </div>
          </div>
          <div className="team-designation">
            <div className="team-head">
              <h3>Heading</h3>
              <input
                type="text"
                name="heading"
                // defaultValue={data?.heading}
                value={data?.heading || ""}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          {(data?.slug === "agency-ip" ||
            data?.slug === "lta" ||
            data?.slug === "corporate-restructuring" ||
            data?.slug === "debt-restructuring-management" ||
            data?.slug === "business-advisory-services" ||
            data?.slug === "asset-liquidation-and-monetization" ||
            data?.slug === "financial-management" ||
            data?.slug === "lta" ||
            data?.slug === "debtor-and-creditor-representation" ||
            data?.slug === "cash-management" ||
            data?.slug === "wellness-reviews"
            
            ) && (
            <div className="team-designation">
              <div className="team-head">
                <h3>Inner Heading</h3>
                <input
                  type="text"
                  name="innerHeading"
                  value={data?.innerHeading || ""}
                  onChange={onChangeHandler}
                />
              </div>
            </div>
          )}
          <div className="team-designation">
            <div className="team-head">
              <h3>Content</h3>

              {/*<textarea*/}
              {/*    className="form-control" name="content"*/}
              {/*    id="exampleModal"*/}
              {/*    rows="3"*/}
              {/*    defaultValue={data?.content}*/}
              {/*    onChange={onChangeHandler}*/}
              {/*></textarea>*/}
              <TextEditor
                placeholder={"Enter"}
                content={data?.content}
                setContent={(value) =>
                  onDirectChangeHandler("content", value, setData)
                }
              />
            </div>
          </div>

          {data?.slug === "agency-ip" && (
            <>
              <div className="team-designation">
                <div className="team-head">
                  <h3>Second Image</h3>

                  <img src={baseUrl + props.singleService?.innerImage} />
                  <input
                    type="file"
                    name="innerImage"
                    accept="image/*"
                    onChange={(e) => onImageChangeHandler(e, setData)}
                    multiple={false}
                  />
                </div>
              </div>
              <div className="team-designation">
                <div className="team-head">
                  <h3>Second Content</h3>
                  {/*<textarea*/}
                  {/*    className="form-control" name="short"*/}
                  {/*    id="short"*/}
                  {/*    rows="3"*/}
                  {/*    defaultValue={data?.short}*/}
                  {/*    onChange={onChangeHandler}*/}
                  {/*></textarea>*/}
                  <TextEditor
                    placeholder={"Enter"}
                    content={data?.short}
                    setContent={(value) =>
                      onDirectChangeHandler("short", value, setData)
                    }
                  />
                  {/*<input*/}
                  {/*    type="text" name="short"*/}
                  {/*    value={data?.short || ''}*/}
                  {/*    onChange={onChangeHandler}*/}
                  {/*/>*/}
                </div>
              </div>
            </>
          )}
          {data?.slug === "wellness-reviews" && (
            <>
              <h3>Section 1</h3>
              <div className="team-img">
                <div className="team-head">
                  <img
                    src={baseUrl + props.singleService?.data?.sectionImage1}
                  />
                  <input
                    type="file"
                    name="sectionImage1"
                    accept="image/*"
                    className="form-control"
                    onChange={(e) => onImageChangeHandler(e, setData)}
                    multiple={false}
                  />
                </div>
              </div>
              <div className="team-img">
                <div className="team-head">
                  <label htmlFor="">Content</label>
                  {/*<textarea*/}
                  {/*    className="form-control" name="sectionContent1"*/}
                  {/*    id="sectionContent1"*/}
                  {/*    rows="3"*/}
                  {/*    defaultValue={props.singleService?.data?.sectionContent1}*/}
                  {/*    onChange={onChangeHandler}*/}
                  {/*></textarea>*/}
                  <TextEditor
                    placeholder={"Enter"}
                    content={data?.sectionContent1}
                    setContent={(value) =>
                      onDirectChangeHandler("sectionContent1", value, setData)
                    }
                  />
                </div>
              </div>

              <h3>Section 2</h3>
              <div className="team-img">
                <div className="team-head">
                  <img
                    src={baseUrl + props.singleService?.data?.sectionImage2}
                  />
                  <input
                    type="file"
                    name="sectionImage2"
                    accept="image/*"
                    className="form-control"
                    onChange={(e) => onImageChangeHandler(e, setData)}
                    multiple={false}
                  />
                </div>
              </div>
              <div className="team-img">
                <div className="team-head">
                  <label htmlFor="">Heading</label>
                  <input
                    type="text"
                    name="sectionHeading2"
                    className="form-control"
                    onChange={(e) => onInputChangeHandler(e, setData)}
                  />
                </div>
              </div>

              <div className="team-img">
                <div className="team-head">
                  <label htmlFor="">Content</label>

                  <TextEditor
                    placeholder={"Enter"}
                    content={data?.sectionContent2}
                    setContent={(value) =>
                      onDirectChangeHandler("sectionContent2", value, setData)
                    }
                  />
                </div>
              </div>

              <h3>Section 3</h3>
              <div className="team-img">
                <div className="team-head">
                  <img
                    src={baseUrl + props.singleService?.data?.sectionImage3}
                  />
                  <input
                    type="file"
                    name="sectionImage3"
                    accept="image/*"
                    className="form-control"
                    onChange={(e) => onImageChangeHandler(e, setData)}
                    multiple={false}
                  />
                </div>
              </div>
              <div className="team-img">
                <div className="team-head">
                  <label htmlFor="">Content</label>
                  {/*<textarea*/}
                  {/*    className="form-control" name="sectionContent3"*/}
                  {/*    id="sectionContent3"*/}
                  {/*    rows="3"*/}
                  {/*    defaultValue={props.singleService?.data?.sectionContent3}*/}
                  {/*    onChange={(e)=>onInputChangeHandler(e,setData)}*/}
                  {/*></textarea>*/}
                  <TextEditor
                    placeholder={"Enter"}
                    content={data?.sectionContent3}
                    setContent={(value) =>
                      onDirectChangeHandler("sectionContent3", value, setData)
                    }
                  />
                </div>
              </div>

              <h3>Section 4</h3>
              <div className="team-img">
                <div className="team-head">
                  <img
                    src={baseUrl + props.singleService?.data?.sectionImage4}
                  />
                  <input
                    type="file"
                    name="sectionImage4"
                    accept="image/*"
                    className="form-control"
                    onChange={(e) => onImageChangeHandler(e, setData)}
                    multiple={false}
                  />
                </div>
              </div>
              <div className="team-img">
                <div className="team-head">
                  <label htmlFor="">Content</label>
                  {/*<textarea*/}
                  {/*    className="form-control" name="sectionContent4"*/}
                  {/*    id="sectionContent4"*/}
                  {/*    rows="3"*/}
                  {/*    defaultValue={props.singleService?.data?.sectionContent4}*/}
                  {/*    onChange={(e)=>onInputChangeHandler(e,setData)}*/}
                  {/*></textarea>*/}
                  <TextEditor
                    placeholder={"Enter"}
                    content={data?.sectionContent4}
                    setContent={(value) =>
                      onDirectChangeHandler("sectionContent4", value, setData)
                    }
                  />
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.hide}>
            Close
          </Button>
          <button
            className="btn btn-primary"
            disabled={loading}
            onClick={(e) => serviceData(e)}
          >
            { loading ? <i className="fa fa-spin fa-spinner"></i> : "Save Changes" }
          </button>
        </Modal.Footer>
      </Modal>
      {/* =========================================== */}
    </>
  );
};

export default ServiceModal;
