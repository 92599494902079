import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { affaltiondelete, affilationform } from "../../network/Network";

function Getaffilation() {
  const [con, setCon] = useState([]);

  const [state, setState] = useState();
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [settingData, setSettingData] = useState([]);
  let limit = 10;
  // Contact Get Api
  useEffect(() => {
    setSpinLoad(true);
    affilationform()
      .then((res) => {
        setCon(res?.data?.data);
        // dispatch(setblogstoress(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);
  // =============================

  // Contact Delete Api
  const DeletecontactHandler = (e, id) => {
    e.preventDefault();
    // setRemoveLoading(true);
    affaltiondelete(id)
      .then((res) => {
        setCon((prev) => {
          return [...prev.filter((val) => val?._id !== id)];
        });
        // toast.success(res?.data?.message);
        // teamMember?.splice(index, 1);
        // setteamMember(res?.data?.response?.data?.data);
        // setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setRemoveLoading(false);
      });
  };
  return (
    <>
      {/* All stuff */}
      <section className="Contact-sec">
        <div className="Conatct-head">
          <h1>All Affilation Form Queries</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="contact-name">
              <table className="contact-table contac-table">
                <thead className="th-submit">
                  <th>Srno.</th>
                  <th>Name</th>
                  <th>Email</th>

                  <th>Phone Number</th>

                  <th>Your Message</th>
                  <th>Del</th>
                </thead>
                {con.map((data, index) => {
                  console.log(data);
                  return (
                    <tr>
                      <td>
                        <h3 key={data?._id}>{index + 1} </h3>
                      </td>
                      <td>
                        <h3>{data?.name}</h3>
                      </td>

                      <td>
                        <h3>{data?.email}</h3>
                      </td>
                      <td>
                        <h3>{data?.phone_no}</h3>
                      </td>

                      <td>
                        <h3>{data?.message}</h3>
                      </td>
                      <td>
                        <i
                          class="fa fa-trash-o"
                          aria-hidden="true"
                          onClick={(e) => DeletecontactHandler(e, data?._id)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </section>
      {/* =============================== */}
    </>
  );
}

export default Getaffilation;
