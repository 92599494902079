import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/DashboardHeader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";
import { Modal, Button } from "react-bootstrap";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import { toast } from "react-toastify";
import {
  blogshow,
  blogstore,
  news,
  newsdelete,
  newsrecord,
  newsupdate,
  PostTeams,
} from "../../network/Network";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../network/ApiUrl";
import TextEditor from "../../components/texteditor/Texteditor";

function Newshow() {
  const dispatch = useDispatch();
  const [Newsstoress, setNewsstoress] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  // const [convertedText, setConvertedText] = useState("Some default content");
  const [name, setName] = useState();
  const [title, setTitle] = useState();
  const [img, setimg] = useState();
  const [state, setState] = useState();
  const [image, setImage] = useState();
  const [des, setdes] = useState();
  const [loading, setLoading] = useState(false);
  const [short, setShort] = useState();
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [editData, setEditData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [selectedData, setSelectedData] = useState();
  const [description, setDescription] = useState();
  const [editstate, setEditState] = useState(false);
  const [imageSent, setImageSent] = useState(null);
  const [fileupload, setFileupload] = useState();
  const [newFile, setNewFile] = useState();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  let limit = 10;

  // News Get Api
  useEffect(() => {
    setSpinLoad(true);
    news()
      .then((res) => {
        setNewsstoress(res?.data?.data);

        // dispatch(setblogstoress(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);
  // =============================
  // const handlePageClick = async (data) => {
  //   console.log(data.selected);
  //   setCurrentPage(data?.selected + 1);
  // };

  // News Delete Api
  const DeleteNewHandler = (e, id) => {
    e.preventDefault();
    console.log("id", id);
    // setRemoveLoading(true);
    newsdelete(id)
      .then((res) => {
        setNewsstoress((prev) => {
          return [...prev.filter((val) => val?._id !== id)];
        });

        // toast.success(res?.data?.message);
        // teamMember?.splice(index, 1);
        // setteamMember(res?.data?.response?.data?.data);
        // setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setRemoveLoading(false);
      });
  };
  // ====================================
  const Updatenewsmember = async (e) => {
    setLoading(true);
    e.preventDefault();
    setLoading(true);
    let data = await new FormData();
    data.append("title", title);
    data.append("image", newFile ? newFile : imageSent);
    data.append("description", description);
    data.append("short", short);
    newsupdate(data, selectedId)
      .then((res) => {
        toast.success("Successfully send");
        setEditState(true);
        setLoading(false);

        setTitle("");
        setImageSent("");
        setDescription("");
        setSelectedId("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        console.log(err);
        setLoading(false);
        setTitle("");
        setImageSent("");
        setDescription("");
        setShort(" ");
        console.log(err);
      });
  };

  const EditStateHandler = (e, item) => {
    e.preventDefault();
    setTitle(item?.title);
    setImage(item?.image);
    setDescription(item?.description);
    setShort(item?.short);
  };
  // =============================

  // Image Handler
  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    // setUploadLoading(true);
  };
  // =====================================
  // console.log(Newsstoress);
  return (
    <>
      {/* All stuff */}
      <section className="Blog-show-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Modal show={show} onHide={handleClose}>
                {/* <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header> */}
                <Modal.Body>
                  <div className="team-img">
                    <div className="team-head">
                      <h3>Upload img</h3>
                    </div>
                    <input type="file"></input>
                  </div>
                  <div className="team-designation">
                    <div className="team-head">
                      <h3>title</h3>
                      <input type="text"></input>
                    </div>
                  </div>
                  {/* <div className="team-designation">
                  <div className="team-head">
                    <h3></h3>
                    <input type="text"></input>
                  </div>
                </div> */}
                  <div className="team-description">
                    <div className="team-head">
                      <h3>Description</h3>
                      {/* <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea> */}
                      <div
                        style={{
                          border: "1px solid black",
                          padding: "2px",
                          minHeight: "400px",
                        }}
                      >
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={setEditorState}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  {/* <Button variant="primary" onClick={PostTeamData}>
                  Save
                </Button> */}
                </Modal.Footer>
              </Modal>
            </div>

            <div className="col-md-12">
              <div className="Blog-dv">
                <a href="/News">ADD NEW</a>
              </div>
            </div>
            <div className="col-md-12">
              <table className="blog-show-table team-table">
                <thead></thead>
                <tbody>
                  {Newsstoress?.map((item, index) => {
                    console.log(item, "lll");
                    return (
                      <tr>
                        <th key={item?._id}>{index + 1} </th>
                        <td className="for-small-content">{item?.title}</td>
                        <td className="for-small-content">{item?.short}</td>
                        <td>
                          <img
                            src={`${baseUrl}${item?.image}`}
                            className="image-fluid"
                          />
                        </td>
                        <td className="for-extra-content">
                          <p>{item?.description}</p>
                        </td>
                        <td>
                          {" "}
                          <i
                            onClick={(e) => DeleteNewHandler(e, item?._id)}
                            class="fa fa-trash-o"
                            aria-hidden="true"
                          ></i>
                          <i
                            class="fa fa-pencil-square-o"
                            aria-hidden="true"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            onClick={(e) => {
                              EditStateHandler(e, item);
                              setSelectedId(item?._id);
                            }}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      {/* ====================================== */}
      {/* Team Modal   */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit Your Team Memeber Info
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="team-img">
                <div className="team-head">
                  <img
                    src={
                      baseUrl +
                      Newsstoress?.find((val) => val?._id === selectedId)?.image
                    }
                    width={"30%"}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple={false}
                  />
                </div>
              </div>
              {/* <div className="team-designation">
                <div className="team-head">
                  <h3>Name</h3>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
              </div> */}

              <div className="team-designation">
                <div className="team-head">
                  <h3>Title</h3>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  ></input>
                </div>
              </div>

              <div className="team-designation">
                <div className="team-head">
                  <h3>Short</h3>
                  <input
                    value={short}
                    onChange={(e) => setShort(e.target.value)}
                    type="text"
                  />
                </div>
              </div>

              <div className="team-description">
                <div className="team-head">
                  <h3>Description</h3>
                  {/* <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea> */}
                  <TextEditor
                    placeholder={"Enter"}
                    content={description}
                    setContent={setDescription}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => Updatenewsmember(e)}
                disabled={loading}
                data-dismiss="modal"
              >
                {loading ? "Loading.." : "Save changes"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ========================= */}
    </>
  );
}

export default Newshow;
