import React, {  useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const TextEditor = (props) => {
	const editor = useRef(null);
	// const [content, setContent] = useState('');

	// const config = useMemo(
	// 	() => {
	// 		readonly: false, // all options from https://xdsoft.net/jodit/doc/,
	// 		placeholder: props?.placeholder || 'Start typings...'
	// 	},
	// 	[props?.placeholder]
	// );

	return (
		<JoditEditor
			ref={editor}
			value={props?.content}
			
			// config={config}
			tabIndex={1} // tabIndex of textarea
			onBlur={newContent => props?.setContent(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={newContent => props?.setContent(newContent)}
		/>
	);
};

export default TextEditor