export const onInputChangeHandler = (e,setState) => {
    let { name , value } = e.target;
    setState((preVal)=>{
        return {
            ...preVal,
            [name] : value
        }
    })
}

export const onDirectChangeHandler = (name,value,setState) => {
    setState((preVal)=>{
        return {
            ...preVal,
            [name] : value
        }
    })
}

export const onImageChangeHandler = (e,setState) => {
    // setBannerImage(e.target.files[0]);
    let { name } = e.target;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
        // setNewFile(file);
        // console.log("file", file);
        setState((preVal)=>{
            return {
                ...preVal,
                [name] : file
            }
        })
        // setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    // setUploadLoading(true);
};

export const toFormData = (columns = [],form) => {
    let x = new FormData();
    columns.map( (val) => {
        x.append(val, form[val]);
    })
    return x;
}

export const changeElementValueInArray = (id , data , name , value) => {
    let x = [...data];
    let indexNo = x.findIndex((val)=> val?._id === id);
    if(indexNo >= 0){
        x[indexNo] = {
            ...x[indexNo],
            [name] : value
        }
    }
    return x;
}

export const swapper = (data , first ,second) => {
    let x = [...data];
    let firstIndexNo = x.findIndex((val)=> val?._id === first?.id);
    let secondIndexNo = x.findIndex((val)=> val?._id === second?.id);
    if(firstIndexNo >= 0){
        x[firstIndexNo] = {
            ...x[firstIndexNo],
            'orderNo' : second?.orderNo
        }
    }
    console.log("First ",x[firstIndexNo]);
    if(secondIndexNo >= 0){
        x[secondIndexNo] = {
            ...x[secondIndexNo],
            'orderNo' : first?.orderNo
        }
    }
    console.log("First ",x[secondIndexNo]);
    return x;
}