import DashboardIcon from '../assets/icons/dashboard.svg';
import ShippingIcon from '../assets/icons/shipping.svg';
import ProductIcon from '../assets/icons/product.svg';
import UserIcon from '../assets/icons/user.svg';

const sidebar_menu = [
    {
        id: 1,
        icon: DashboardIcon,
        path: '/',
        title: 'Dashboard',
    },
    {
        id: 2,
        icon: DashboardIcon,
        path: '/home',
        title: 'Home',
    },
    {
        id: 3,
        icon: DashboardIcon,
        path: '/about',
        title: 'About',
    },
    {
        id: 4,
        icon: DashboardIcon,
        path: '/services',
        title: 'Services',
    },
    {
        id: 5,
        icon: DashboardIcon,
        path: '/businessadvisory',
        title: 'Business-Advisory-Services',
    },
    {
        id: 21,
        icon: DashboardIcon,
        path: '/president',
        title: 'President',
    },
    {
        id: 12,
        icon: DashboardIcon,
        path: '/asset-liquidation-and-monetization',
        title: 'Asset-Liquidation & Monetization',
    },
    {
        id: 16,
        icon: DashboardIcon,
        path: '/agencyip',
        title: 'Agency-ip',
    },
    {
        id: 17,
        icon: DashboardIcon,
        path: '/lta',
        title: 'Lta',
    },
    {
        id: 19,
        icon: DashboardIcon,
        path: '/sf',
        title: 'Sherwood-Finance',
    },
    {
        id: 14,
        icon: DashboardIcon,
        path: '/sherwoodpartners',
        title: 'Sherwood-partners',
    },
    {
        id: 15,
        icon: DashboardIcon,
        path: '/firsttime',
        title: 'When You Want It Done Right, The First Time',
    },
    {
        id: 6,
        icon: DashboardIcon,
        path: '/affilation',
        title: 'Affilation',
    },
    {
        id: 24,
        icon: DashboardIcon,
        path: '/getaffialtion',
        title: 'Affilations-Form',
    },
    {
        id: 7,
        icon: ProductIcon,
        path: '/blogshow',
        title: 'Blogs',
    },
    {
        id: 8,
        icon: ShippingIcon,
        path: '/teams',
        title: 'Teams',
    },
    {
        id: 9,
        icon: UserIcon,
        path: '/Contactus',
        title: 'Contact Us',
    },
    {
        id: 10,
        icon: UserIcon,
        path: '/Newshow',
        title: 'News',
    },
    {
        id: 11,
        icon: UserIcon,
        path: '/Career',
        title: 'Career',
    },
    {
        id: 12,
        icon: UserIcon,
        path: '/Careerapplication',
        title: 'CareerApplication',
    },
    {
        id: 18,
        icon: DashboardIcon,
        path: '/others',
        title: 'Others',
    },
    {
        id: 19,
        icon: DashboardIcon,
        path: '/newsletter',
        title: 'Newsletter',
    },
    {
        id: 20,
        icon: DashboardIcon,
        path: '/submitip',
        title: 'Sumbit-ip',
    },
    {
        id: 22,
        icon: DashboardIcon,
        path: '/sales',
        title: 'Sales',
    },
    {
        id: 23,
        icon: DashboardIcon,
        path: '/purchase',
        title: 'Purchase',
    }
    
    
   
]


export default sidebar_menu;