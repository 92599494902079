import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  USER_TOKEN,
  ALL_BLOGS,
  ALL_NEWS,
  All_JOB_DATA,
  ALL_TEAM_DATA,
} from "../Types";
import { postSignIn } from "../../network/Network";
import { toast } from "react-toastify";
import { setError } from "../actions/AlertAction";
import { responses, responsesLogin } from "../../constants/ConstantFunction";

export const login =
  (data, cb = () => {}) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      dispatch({ type: LOGIN_REQUEST });
      postSignIn(data)
        .then((res) => {
          // console.log("Login",{response : { ...res}});
          //  if(res.data.){
          console.log("user",res);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res?.data?.data?.userData,
          });
          dispatch({
            type: USER_TOKEN,
            payload: res?.data?.data?.token,
          });
          // }

          dispatch(setError(responsesLogin(res)));

          return resolve(true);
        })
        .catch((err) => {
          console.log("Login", err.response);
          toast.error("Please Enter Correct Email or Password");
          dispatch(setError(responses(err)));
          // toast.error(err?.response?.data?.payload?.message);
          dispatch({ type: LOGIN_ERROR });
          return reject(false);
        });
    });

export const Logout = (payload) => {
  return {
    type: LOGOUT,
    payload,
  };
};

export const blogstoress = (payload) => {
  return {
    type: ALL_BLOGS,
    payload,
  };
};

export const newstoress = (payload) => {
  return {
    type: ALL_NEWS,
    payload,
  };
};

export const car = (payload) => {
  return {
    type: All_JOB_DATA,
    payload,
  };
};
export const Allteam = (payload) => {
  return {
    type: ALL_TEAM_DATA ,
    payload,
  };
};