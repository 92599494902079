import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/DashboardHeader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";
import { Modal, Button } from "react-bootstrap";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import { toast } from "react-toastify";
import {
  Blogdelete,
  blogshow,
  blogstore,
  blogsupdate,
  PostTeams,
} from "../../network/Network";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../network/ApiUrl";
import TextEditor from "../../components/texteditor/Texteditor";

function Blogshow() {
  const dispatch = useDispatch();
  const [blogstoress, setblogstoress] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  // const [convertedText, setConvertedText] = useState("Some default content");
  const [name, setName] = useState();
  const [title, setTitle] = useState();
  const [img, setimg] = useState();
  const [state, setState] = useState();
  const [image, setImage] = useState();
  const [des, setdes] = useState();
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [description, setDescription] = useState();
  const [newFile, setNewFile] = useState();
  const [imageSent, setImageSent] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const [fileupload, setFileupload] = useState();
  const [editstate, setEditState] = useState(false);
  const [editData, setEditData] = useState([]);
  let limit = 10;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    setSpinLoad(true);
    blogshow()
      .then((res) => {
        setblogstoress(res?.data?.data);
        // dispatch(setblogstoress(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  //   const Addnewblog = async (e) => {
  //     e.preventDefault();
  //     setLoading(true);
  //     let data = await new FormData();
  //     data.append("title", title);
  //     blogstore(data)
  //       .then((res) => {
  //         console.log(res);
  //         toast.success(res?.data?.message);
  //         blogstoress.splice(
  //           res?.data?.response?.data.length + 1,
  //           0,
  //           res?.data?.response?.data
  //         );
  //         // dispatch(AllServices(res?.data?.response?.data));
  //         setLoading(false);
  //         setIsOpenModal(false);
  //         setTitle(" ");
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         toast.error(err?.data?.message);
  //         setLoading(false);
  //         setTitle(" ");
  //       });
  //   };
  // }, [limit, currentPage]);
  // const Advise=[

  // ];
  //   const [show, setShow] = useState(false);
  //   const [teamMember, setteamMember] = useState([]);
  //   const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);
  //   // const [convertedText, setConvertedText] = useState("Some default content");
  //   const [name, setName] = useState();
  //   const [title, setTitle] = useState();
  //   const [state, setState] = useState();
  //   const [image, setImage] = useState();

  //   const [editorState, setEditorState] = useState(() =>
  //     EditorState.createEmpty()
  //   );

  //   const [description, setDescription] = useState();
  //   const PostTeamData = (e) => {
  //     e.preventDefault();
  //     var data = new FormData();
  //     data.append("name", name);
  //     data.append("title", title);
  //     data.append("image", image);
  //     data.append("description", editorState);
  //     console.log(data);
  //     PostTeams(data)
  //       .then((res) => {
  //         console.log(res);
  //         handleClose();
  //         toast.success("Successfully send");
  //       })
  //       .catch((err) => {
  //         toast.error(err?.response?.data?.errors);
  //         console.log(err);
  //       });
  //   };

  //   const SchooliesApi = async () => {
  //     try {
  //       const { data } = await axios.get(SINGLE_TEAM_MEMBER);
  //       console.log(data.response.data);
  //       setState(data.response.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  const DeleteblogtHandler = (e, id) => {
    e.preventDefault();
    // setRemoveLoading(true);
    Blogdelete(id)
      .then((res) => {
        setblogstoress((prev) => {
          return [...prev.filter((val) => val?._id !== id)];
        });
        // toast.success(res?.data?.message);
        // teamMember?.splice(index, 1);
        // setteamMember(res?.data?.response?.data?.data);
        // setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setRemoveLoading(false);
      });
  };

  const Updateblogmember = async (e) => {
    setLoading(true);
    e.preventDefault();
    setLoading(true);
    let data = await new FormData();
    data.append("title", title);
    data.append("image", newFile ? newFile : imageSent);
    data.append("description", description);
    blogsupdate(data, selectedId)
      .then((res) => {
        toast.success("Successfully send");
        setEditState(true);
        setLoading(false);
       
        setTitle("");
        setImageSent("");
        setDescription("");
        setSelectedId("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        console.log(err);
        setLoading(false);
        setTitle("");
        setImageSent("");
        setDescription("");
        console.log(err);
      });
  };

      // Image Handler
      const handleImageUpload = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
          setNewFile(file);
          setFileupload(reader.result);
        };
        reader.readAsDataURL(file);
        // setUploadLoading(true);
      };
      // =====================================

      const EditStateHandler = (e, item) => {
        console.log("gvhvhgjgj", item)
        e.preventDefault();
        setTitle(item?.title);
        setImage(item?.image);
        setDescription(item?.description);
      };
  return (
    <>
      <section className="Blog-show-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Modal show={show} onHide={handleClose}>
                {/* <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header> */}
                <Modal.Body>
                  <div className="team-img">
                    <div className="team-head">
                      <h3>Upload img</h3>
                    </div>
                    <input type="file"></input>
                  </div>
                  <div className="team-designation">
                    <div className="team-head">
                      <h3>title</h3>
                      <input type="text"></input>
                    </div>
                  </div>

                  {/* <div className="team-designation">
                  <div className="team-head">
                    <h3></h3>
                    <input type="text"></input>
                  </div>
                </div> */}

                  <div className="team-description">
                    <div className="team-head">
                      <h3>Description</h3>
                      {/* <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea> */}
                      <div
                        style={{
                          border: "1px solid black",
                          padding: "2px",
                          minHeight: "400px",
                        }}
                      >
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={setEditorState}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  {/* <Button variant="primary" onClick={PostTeamData}>
                  Save
                </Button> */}
                </Modal.Footer>
              </Modal>
            </div>

            <div className="col-md-12">
              <div className="Blog-dv">
                <a href="/blogs">ADD NEW</a>
              </div>
            </div>

            <div className="col-md-12">
              <table className="blog-show-table blog-table">
                <thead></thead>

                <tbody>
                  {blogstoress?.map((item, index) => {
                    return (
                      <tr>
                        <th key={item?.id}>{index + 1} </th>
                        {/* <th>
                          <h2>Title</h2>
                        </th>
                        <th>
                          <h2>Image</h2>
                        </th>
                        <th>
                          <h2>Description</h2>
                        </th>{" "} */}

                        <td>{item?.title}</td>
                        <td>
                          <img
                            src={`${baseUrl}${item?.image}`}
                            className="image-fluid"
                          />
                        </td>
                        <td>
                          <p>{item?.description}</p>
                        </td>
                        <td>
                          {" "}
                          <i
                        class="fa fa-pencil-square-o"
                        aria-hidden="true"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={(e) => {
                          EditStateHandler(e, item);
                          setSelectedId(item?._id);
                        }}
                      ></i>
                          <i
                            class="fa fa-trash-o"
                            aria-hidden="true"
                            onClick={(e) => DeleteblogtHandler(e, item?._id)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div> */}
          </div>
        </div>
      </section>

          {/* ====================================== */}
      {/* Team Modal   */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit Your Team Memeber Info
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="team-img">
                <div className="team-head">
                  <img src={baseUrl + blogstoress?.image} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple={false}
                  />
                </div>
              </div>
              {/* <div className="team-designation">
                <div className="team-head">
                  <h3>Name</h3>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
              </div> */}

              <div className="team-designation">
                <div className="team-head">
                  <h3>Title</h3>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  ></input>
                </div>
              </div>

              <div className="team-description">
                <div className="team-head">
                  <h3>Description</h3>
                  {/* <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea> */}
                  <TextEditor
                    placeholder={"Enter"}
                    content={description}
                    setContent={setDescription}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => Updateblogmember(e)}
                disabled={loading}
                data-dismiss="modal"
              >
                {loading ? "Loading.." : "Save changes"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ========================= */}
    </>
  );
}

export default Blogshow;
