import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify";
import {baseUrl} from "../../network/ApiUrl";
import {addBusinessAdvisoryServices, servicesadd} from "../../network/Network";
import {
    onDirectChangeHandler,
    onImageChangeHandler,
    onInputChangeHandler,
} from "../../utils/helpers";
import TextEditor from "../texteditor/Texteditor";

const BusinessModal = (props) => {
    const [heading, setHeading] = useState();
    const [fileupload, setFileupload] = useState();
    const [bannerImage, setBannerImage] = useState(null);
    const [newFile, setNewFile] = useState();
    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        heading: "",
        innerHeading: "",
        short: "",
        slug: "",
        bannerImage: null,
        content: "",
        section_two_image: null,
        section_two_content: "",
    });
    const onChangeHandler = (e) => {
        let {name, value} = e.target;
        setData((preVal) => {
            return {
                ...preVal,
                [name]: value,
            };
        });
    };
    //   const {
    //     serviceData,
    //     loading,
    //   } = props;

    // Image Handler
    const handleImageUpload = (e) => {
        setBannerImage(e.target.files[0]);
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            setNewFile(file);
            console.log("file", file);
            setData((preVal) => ({
                ...preVal,
                bannerImage: file,
            }));
            setFileupload(reader.result);
        };
        reader.readAsDataURL(file);
        // setUploadLoading(true);
    };
    // ====================================

    // Service Post Api
    const serviceData = (e) => {
        console.log(data);
        e.preventDefault();
        let x = new FormData();
        x.append("heading", data.heading);
        if (data.bannerImage) x.append("bannerImage", data.bannerImage);
        if (data.section_two_image) x.append("section_two_image", data.section_two_image);
        x.append("content", data.content);
        x.append("innerHeading", data?.innerHeading || "");
        x.append("slug", data.slug);
        x.append("short", data.short);
        x.append("section_two_content", data.section_two_content);
        setLoading(true);
        addBusinessAdvisoryServices(x)
            .then((res) => {
                setLoading(false);
                props.hide();
                toast.success("Successfully send");
            })
            .catch((err) => {
                setLoading(false);
                // toast.error(err?.response?.data?.errors);
                console.log(err);
            });
    };
    // =================================

    // Service Get Api
    useEffect(() => {
        setData(() => ({
            heading: props.singleService?.heading ,
            innerHeading: props.singleService?.innerHeading ,
            bannerImage: null ,
            slug: props.singleService?.slug ,
            content: props.singleService?.content ,
            short: props?.singleService?.short ,
            section_two_image: null ,
            section_two_content: props?.singleService?.data?.section_two_content ,
        }));
        console.log("props.singleService", props.singleService);
    }, [props.singleService]);
    // ============================
    console.log("data",data);
    return (
        <>
            {/* All stuff */}
            <Modal
                show={props.show}
                onHide={() => {
                    props.hide();
                    // props.setService(null);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="team-img">
                        <div className="team-head">
                            <img src={baseUrl + props.singleService?.bannerImage}/>
                            <input
                                type="file"
                                name="bannerImage"
                                accept="image/*"
                                onChange={handleImageUpload}
                                multiple={false}
                            />
                        </div>
                    </div>

                    <div className="team-designation">
                        <div className="team-head">
                            <h3>Heading</h3>
                            <input
                                type="text"
                                name="heading"
                                // defaultValue={data?.heading}
                                value={data?.heading || ""}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>

                    <div className="team-designation">
                        <div className="team-head">
                            <h3>Inner Heading</h3>
                            <input
                                type="text"
                                name="innerHeading"
                                value={data?.innerHeading || ""}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>

                    <div className="team-designation">
                        <div className="team-head">
                            <h3>Short Content</h3>

                            <TextEditor
                                placeholder={"Enter"}
                                content={data?.short}
                                setContent={(value) =>
                                    onDirectChangeHandler("short", value, setData)
                                }
                            />
                        </div>
                    </div>

                    <div className="team-designation">
                        <div className="team-head">
                            <h3>Content</h3>

                            <TextEditor
                                placeholder={"Enter"}
                                content={data?.content}
                                setContent={(value) =>
                                    onDirectChangeHandler("content", value, setData)
                                }
                            />
                        </div>
                    </div>


                            <h3>Section 2</h3>
                            <div className="team-img">
                                <div className="team-head">
                                    <img
                                        src={baseUrl + props.singleService?.data?.section_two_image}
                                    />
                                    <input
                                        type="file"
                                        name="section_two_image"
                                        accept="image/*"
                                        className="form-control"
                                        onChange={(e) => onImageChangeHandler(e, setData)}
                                        multiple={false}
                                    />
                                </div>
                            </div>
                            <div className="team-img">
                                <div className="team-head">
                                    <label htmlFor="">Content</label>
                                    <TextEditor
                                        placeholder={"Enter"}
                                        content={data?.section_two_content}
                                        setContent={(value) =>
                                            onDirectChangeHandler("section_two_content", value, setData)
                                        }
                                    />
                                </div>
                            </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hide}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        disabled={loading}
                        onClick={(e) => serviceData(e)}
                    >
                        {loading ? (
                            <i className="fa fa-spin fa-spinner"></i>
                        ) : (
                            "Save Changes"
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* =========================================== */}
        </>
    );
};

export default BusinessModal;
