export const ALERTS = "ALERTS";
export const SET_ALERTS ="SET_ALERTS";

export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const USER_TOKEN = "USER_TOKEN";

export const ALL_BLOGS = "ALL_BLOGS";
export const ALL_NEWS = "ALL_NEWS";
export const All_JOB_DATA = "All_JOB_DATA";
export const ALL_TEAM_DATA = "ALL_TEAM_DATA";

