import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    USER_TOKEN,
    ALL_BLOGS,
    ALL_NEWS,
    All_JOB_DATA,
    ALL_TEAM_DATA,
  
  } from "../Types";

  const initialState = {
    isLogin: false,
    loading: false,
    // forgotLoading: false,
    // setPasswordLoading: false,
    users: {},
    token: "",
    blogs: [],
    allteam: [],
  };


  const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_REQUEST: {
        return Object.assign({}, state, {
          loading: true,
        });
      }
      case LOGIN_SUCCESS: {
        return Object.assign({}, state, {
          users: action.payload,
          loading: false,
          isLogin: true,
        });
      }
      case LOGIN_ERROR: {
        return Object.assign({}, state, {
          loading: false,
          isLogin: false,
        });
      }
      case USER_TOKEN: {
        return Object.assign({}, state, {
          token: action.payload,
        });
      }
  
     
      case LOGOUT:
        return {
          users: null,
          isLogin: false,
        };

        case ALL_BLOGS: {
          return Object.assign({}, state, {
            ...state,
            department: action.payload,
          });
        }
        
        case ALL_NEWS: {
          return Object.assign({}, state, {
            ...state,
            department: action.payload,
          });
        }
        case All_JOB_DATA: {
          return Object.assign({}, state, {
            ...state,
            car: action.payload,
          });
        }

        case ALL_TEAM_DATA: {
          return Object.assign({}, state, {
            ...state,
            country: action.payload,
          });
        }
  
      //   case JOBS:
      //     return {
      //       ...state,
      //         jobs: action.payload,
      //   };
      //
      //
      //   case NOTIFICATION: {
      //     return Object.assign({}, state, {
      //       ...state,
      //       notifications: action.payload,
      //     });
      //   }
      //   case CHAT_SEND: {
      //     return Object.assign({}, state, {
      //       ...state,
      //       outgoingMessage: action.payload,
      //     });
      //   }
      //   case SERVICES: {
      //     return Object.assign({}, state, {
      //       ...state,
      //       services: action.payload,
      //     });
      //   }
      //   case PROVIDERSERVICEDATA: {
      //     return Object.assign({}, state, {
      //       ...state,
      //       providerServiceData: action.payload,
      //     });
      //   }
      //   case USER_MESSAGES: {
      //     return Object.assign({}, state, {
      //       ...state,
      //       UserMessages: action.payload,
      //     });
      //   }
      //   case CHAT_USERDATA: {
      //     return Object.assign({}, state, {
      //       ...state,
      //       ChatUserData: action.payload,
      //     });
      //   }
      default:
        return state;
    }
  };
  export default AuthReducer;